import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import StandartPage from '../Layouts/Shared/_StandartPage';

export default function ChangedLanguage() {

    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, [navigate]);

    return (
        <StandartPage title={'Changed Language'} topCategoryIsEnabled={true} topSliderIsEnabled={false} >
            <React.Fragment>
            </React.Fragment>
        </StandartPage>
    )

}