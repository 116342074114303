import React, { useEffect, useState } from 'react';
import {  } from 'reactstrap';
import FooterCategoryItem from './FooterCategoryItem';


const FooterCategoryList = (props) => {

    const [data, setData] = useState(null);
    useEffect(() => {
        setData(props?.dataCategoryList);
    }, [props]);

    return (
        <React.Fragment>
                {data ? ((
                    data?.payload?.[0]?.['items']?.map((item, key) => (
                        <FooterCategoryItem key={key} itemDetails={item} />
                    ))
                )) : (
                    <p>Loading...</p>
                )}
        </React.Fragment >
    )
};

export default FooterCategoryList;