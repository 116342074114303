import React, { useEffect, useState } from 'react';
import ProductListItem from './ProductListItem';

import { Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import axios from 'axios';

const ProductList = (props) => {


    let categoryId = props?.categoryId;
    let customModePropertShowcase = props?.showcase;
    let customModePropertLimit = props?.limit;

    let paginationItemClassName = '';
    let paginationItemActiveClassName = '';

    let styleOfPaginationItem = {};
    let styleOfPaginationItemActive = {};


    const [pageSize, setPageSize] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [paginationClassName, setPaginationClassName] = useState('');

    const [data, setData] = useState(null);


    useEffect(() => {
        if (customModePropertShowcase) {
            setPageSize(customModePropertLimit);
            setPaginationClassName('d-none');
        } else {
            setPaginationClassName('');
        }
        if ((sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') || true) {
            let currentSession = sessionStorage.getItem("currentSession");
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.get(ApiGateway + '/market-place/bazaar/item/' + categoryId + '/list?pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&sort=id,ASC')
                .then(response => {
                    setData(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
        }

    }, [categoryId, pageNumber, pageSize, paginationClassName, customModePropertLimit, customModePropertShowcase]);

    return (
        <React.Fragment>
            <Row>
                {data ? ((
                    data?.payload?.content.map((item, key) => (
                        <ProductListItem key={key} itemDetails={item} />
                    ))
                )) : (
                    <p>Loading...</p>
                )}
            </Row>

            <Row>
                <Col md={12}>
                    <Pagination aria-label="Page navigation example" className={paginationClassName}>
                        <>
                            {(() => {
                                const arr = [];
                                for (let i = 0; i < data?.payload?.totalPages; i++) {
                                    pageNumber === i ? (
                                        arr.push(
                                            <PaginationItem key={i} active style={styleOfPaginationItemActive} className={paginationItemActiveClassName}>
                                                <PaginationLink href="#" onClick={(e) => { setPageNumber(i) }}>
                                                    {i + 1}
                                                </PaginationLink>
                                            </PaginationItem>)
                                    ) : (
                                        arr.push(
                                            <PaginationItem key={i} style={styleOfPaginationItem} className={paginationItemClassName}>
                                                <PaginationLink href="#" onClick={(e) => { setPageNumber(i) }}>
                                                    {i + 1}
                                                </PaginationLink>
                                            </PaginationItem>)
                                    )

                                }
                                return arr;
                            })()}
                        </>

                    </Pagination>
                </Col>
            </Row>
        </React.Fragment >
    )
};

export default ProductList;