import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Input, Button, Label, Spinner } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import accessImage from '../../Assets/Images/login/access.jpg'
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import StandartPage from '../../Layouts/Shared/_StandartPage';

export default function Login() {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [email, setEMail] = useState('');
    const [password, setPassword] = useState('');

    const [seed, setSeed] = useState(0);
    const [data, setData] = useState(null);
    const [apiCalled, setApiCalled] = useState(false);

    useEffect(() => {
        setApiCalled(false);
        if (data?.isSucceed === true) {
            let message = t('Api.Default.Service.Response.Succeded');
            toast.success(message, { autoClose: 1000 });
            sessionStorage.setItem("currentSession", JSON.stringify(data?.payload));
            setSeed(Math.random());
            navigate('/changed-language');
        } else {
            data?.messages?.forEach(
                item => toast.error(item.message, { autoClose: 5000 })
            )
            setSeed(Math.random());
        }
    }, [t, data, navigate]);

    const callLogin = (btn) => {
        let requestBody = {
            email: email,
            password: password
        };

        axios.defaults.headers.common["X-User-Language"] = 'TR';
        let ApiGateway = process.env.REACT_APP_API_URL;
        axios.post(ApiGateway + '/access/login/unauth', requestBody)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error(error);
            });
        setApiCalled(true);
    };
    return (
        <StandartPage title={t('Login.Title')} topCategoryIsEnabled={true} topSliderIsEnabled={false} >
            <React.Fragment>
                <ToastContainer />
                <Row>
                    <Col className="col-md-6">
                        <img src={accessImage} alt={''} width={700} height={400} className='img-fluid' />
                    </Col>
                    <Col className="col-md-6">
                        <Form onSubmit={e => e.preventDefault()}>
                            <Row>
                                <Col className="col-md-12">
                                    <h4>{t('Login.Title')}</h4>
                                    <hr style={{
                                        color: '#2e4053',
                                        backgroundColor: '#2e4053',
                                        height: .5,
                                        borderColor: '#2e4053',
                                        paddingBottom: '2px',
                                        marginTop: '5px'
                                    }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-md-6">
                                    <FormGroup>
                                        <Label>{t('Login.Label.LoginName')} <span className="text-danger">*</span> </Label>
                                        <Input placeholder={t('Login.PlaceHolder.Email')} onChange={e => setEMail(e.target.value)} onKeyUp={e => setEMail(e.target.value)} />
                                    </FormGroup>
                                </Col>
                                <Col className="col-md-6">
                                    <FormGroup>
                                        <Label>{t('Login.Label.Password')} <span className="text-danger">*</span> </Label>
                                        <Input type="password" placeholder={t('Login.PlaceHolder.Password')} onChange={e => setPassword(e.target.value)} onKeyUp={e => setPassword(e.target.value)} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-md-12">
                                    <FormGroup>
                                        <Button onClick={e => callLogin(e)} className={apiCalled === true ? 'btn btn-dark border-0 border w-100 disabled' : 'btn btn-dark border-0 border w-100'}>
                                            <Spinner className={apiCalled === true ? '' : 'd-none'}
                                                size="sm"
                                                color="light"
                                            >
                                            </Spinner>
                                            <span className={apiCalled !== true ? '' : 'd-none'}>{t('Login.Button.Login')}</span>
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col className="col-md-0"></Col>
                </Row>
            </React.Fragment>
        </StandartPage>
    )

}