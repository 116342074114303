import React from 'react';
import StandartPage from '../Layouts/Shared/_StandartPage';

export default function Other() {


    return (
        <StandartPage title={'Other Page'} topCategoryIsEnabled={true} topSliderIsEnabled={false} >
            <React.Fragment>
                <div><h1>Content</h1></div>
            </React.Fragment>
        </StandartPage>
    )

}