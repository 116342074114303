import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import CategoryItem from './CategoryItem';
import CategorySlider from './CategorySlider';



const Categoryist = (props) => {

    const [data, setData] = useState(null);
    const [sliderClassName, setSliderClassName] = useState('');
    
    useEffect(() => {
        setData(props?.dataCategoryList);
    }, [props, sliderClassName]);

    useEffect(() => {
        if(props?.slider === true){
            setSliderClassName('mt-2 mb-0');
        }else{
            setSliderClassName('d-none');
        } 
    }, [props]);
 
    return (
        <React.Fragment>
            <Row>
                {data ? ((
                    data?.payload?.[0]?.['items']?.map((item, key) => (
                        <CategoryItem key={key} itemDetails={item} />
                    ))
                )) : (
                    <p>Loading...</p>
                )}

            </Row>
            <Row>
                <Col md={12} className={sliderClassName}>
                    <CategorySlider />
                </Col>
            </Row>
        </React.Fragment >
    )
};

export default Categoryist;