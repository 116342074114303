import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormGroup, Input, Button, Label, Spinner, FormFeedback } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import accessImage from '../../Assets/Images/login/access.jpg'
import { useTranslation } from "react-i18next";
import StandartPage from '../../Layouts/Shared/_StandartPage';
import axios from 'axios';
import { InputMask } from 'primereact/inputmask';

export default function Register() {

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [email, setEMail] = useState('');
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');

    const [seed, setSeed] = useState(0);
    const [data, setData] = useState(null);
    const [apiCalled, setApiCalled] = useState(false);

    useEffect(() => {
        setApiCalled(false);
        if (data?.isSucceed === true) {
            let message = t('Api.Default.Service.Response.Succeded');
            toast.success(message, { autoClose: 1000 });
            sessionStorage.setItem("currentSession", JSON.stringify(data?.payload));
            setSeed(Math.random());
            navigate('/changed-language');
        } else {
            data?.messages?.forEach(
                item => toast.error(item.message, { autoClose: 2000 })
            )
            setSeed(Math.random());
        }
    }, [t, data, navigate]);

    const callRegister = (btn) => {
        let requestBody = {
            firstName: firstName,
            lastName: lastName,
            mobileNumber: mobileNumber,
            email: email,
            password1: password1,
            password2: password2
        };

        axios.defaults.headers.common["X-User-Language"] = 'TR';
        let ApiGateway = process.env.REACT_APP_API_URL;
        axios.post(ApiGateway + '/access/login/registerr', requestBody)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error(error);
            });
        setApiCalled(true);
    };

    return (
        <StandartPage title={t('Register.Title')} topCategoryIsEnabled={true} topSliderIsEnabled={false} >
            <React.Fragment>
                <ToastContainer />
                <Row>
                    <Col className="col-md-6">
                        <img src={accessImage} alt={''} width={700} height={400} className='img-fluid' />
                    </Col>
                    <Col className="col-md-6">
                        <Form onSubmit={e => e.preventDefault()}>
                            <Row>
                                <Col className="col-md-12">
                                    <h4>{t('Register.Title')}</h4>
                                    <hr style={{
                                        color: '#2e4053',
                                        backgroundColor: '#2e4053',
                                        height: .5,
                                        borderColor: '#2e4053',
                                        paddingBottom: '2px',
                                        marginTop: '5px'
                                    }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-md-6">
                                    <FormGroup>
                                        <Label>{t('Register.Label.FirstName')} <span className="text-danger">*</span> </Label>
                                        <Input placeholder={t('Register.PlaceHolder.FirstName')} onChange={e => setFirstName(e.target.value)} onKeyUp={e => setFirstName(e.target.value)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t('Register.Label.LastName')} <span className="text-danger">*</span> </Label>
                                        <Input placeholder={t('Register.PlaceHolder.LastName')} onChange={e => setLastName(e.target.value)} onKeyUp={e => setLastName(e.target.value)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t('Register.Label.MobileNumber')} <span className="text-danger">*</span> </Label>
                                        <InputMask className={"form-control"} mask="(599) 999-9999" placeholder="(599) 999-9999" onChange={e => setMobileNumber(e.target.value)} onKeyUp={e => setMobileNumber(e.target.value)} />
                                    </FormGroup>
                                </Col>
                                <Col className="col-md-6">
                                    <FormGroup>
                                        <Label>{t('Register.Label.Email')} <span className="text-danger">*</span> </Label>
                                        <Input placeholder={t('Register.PlaceHolder.Email')} onChange={e => setEMail(e.target.value)} onKeyUp={e => setEMail(e.target.value)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t('Register.Label.Password')} <span className="text-danger">*</span> </Label>
                                        <Input type="password" placeholder={t('Register.PlaceHolder.Password')} onChange={e => setPassword1(e.target.value)} onKeyUp={e => setPassword1(e.target.value)} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Label>{t('Register.Label.ConfirmPassword')} <span className="text-danger">*</span> </Label>
                                        <Input type="password" placeholder={t('Register.PlaceHolder.ConfirmPassword')} onChange={e => setPassword2(e.target.value)} onKeyUp={e => setPassword2(e.target.value)} />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="col-md-12">
                                    <FormGroup>
                                        <Button onClick={e => callRegister(e)} className={apiCalled === true ? 'btn btn-dark border-0 border w-100 disabled' : 'btn btn-dark border-0 border w-100'}>
                                            <Spinner className={apiCalled === true ? '' : 'd-none'}
                                                size="sm"
                                                color="light"
                                            >
                                            </Spinner>
                                            <span className={apiCalled !== true ? '' : 'd-none'}>{t('Register.Button.Register')}</span>
                                        </Button>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                    <Col className="col-md-0"></Col>
                </Row>
            </React.Fragment>
        </StandartPage>
    )

}