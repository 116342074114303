import React, { useEffect, useState } from 'react';
import { Input, Row, Col, Nav, NavItem, Navbar, NavbarBrand, NavbarToggler, Collapse, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, NavbarText, Button } from 'reactstrap';
import { Link, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import LanguageBar from './LanguageBar';

const NavBar = () => {

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [seed, setSeed] = useState(0);
    const [logged, setLogged] = useState(false);

    const [displayName, setDisplayName] = useState(false);


    useEffect(() => {
        if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
            let currentSession = sessionStorage.getItem("currentSession");
            setDisplayName(JSON.parse(currentSession)?.displayName);
            setSeed(Math.random());
            setLogged(true);
        } else {
            setSeed(Math.random());
            setLogged(false);
        }
    }, []);

    useEffect(() => {

    }, [logged]);

    const callLogout = (btn) => {
        sessionStorage.removeItem("currentSession");
        setSeed(Math.random());
        Navigate('/changed-language');
    };


    let classOfNavItem = 'btn btn-light';
    let styleOfNavItem = { fontSize: 15, textAlign: 'left', marginRight: '10px' };
    return (
        <React.Fragment>
            <Row key={seed}>
                <Col className={'col-md-7 pull-left'}>
                    <Navbar expand="lg" bg="dark" variant="dark" className="navbar container navbar-toggleable-sm navbar-inverse">
                        <NavbarBrand href="/"><span className="mb-0 h1 text-dark">Yuh Deve</span></NavbarBrand>
                        <NavbarToggler onClick={toggle} />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav className="me-auto" navbar style={{ fontSize: 15, textAlign: 'left', textDecorationLine: 'underline' }}>
                                <NavItem>
                                    <Link to="/" className={classOfNavItem} style={styleOfNavItem} >
                                        {t('Dashboard.Title')}</Link>
                                </NavItem>
                                {(() => {
                                    if (logged === true) {
                                        return (
                                            <>
                                                <NavItem>
                                                    <Link to="/my-account" className={classOfNavItem} style={styleOfNavItem} >
                                                        {displayName} </Link>
                                                </NavItem>
                                                <NavItem>
                                                    <Link to="/my-cbi-process-list" className={classOfNavItem} style={styleOfNavItem} >
                                                        {t('Product.Common.Label.Bpm.Mp.Cbi.MyProcess.List')} </Link>
                                                </NavItem>
                                                <NavItem>
                                                    <Link className={classOfNavItem} style={styleOfNavItem} onClick={e => { callLogout(e) }} >
                                                        {t('ProfileDropdown.Logout')}</Link>
                                                </NavItem>
                                            </>
                                        )
                                    } else {
                                        return (
                                            <>
                                                <NavItem>
                                                    <Link to="/register" className={classOfNavItem} style={styleOfNavItem} >
                                                        {t('Login.Label.Link.Register')}</Link>
                                                </NavItem>
                                                <NavItem>
                                                    <Link to="/login" className={classOfNavItem} style={styleOfNavItem} >
                                                        {t('Register.Label.Login')}</Link>
                                                </NavItem>
                                            </>

                                        )
                                    }
                                })()}
                                <UncontrolledDropdown nav inNavbar className='d-none'>
                                    <DropdownToggle nav caret>
                                        Options
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>Option 1</DropdownItem>
                                        <DropdownItem>Option 2</DropdownItem>
                                        <DropdownItem divider />
                                        <DropdownItem>Reset</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                                <NavbarText>
                                    
                                </NavbarText>
                            </Nav>
                        </Collapse>
                    </Navbar>
                </Col>
                <Col className={'col-md-5 pull-right'}>
                <LanguageBar />
                </Col>
            </Row>
            <Row className='mt-0 float-end' style={{textAlign: 'right'}}>
                <Col className={'col-md-7 d-flex'}>
                    <Input
                        id="exampleEmail"
                        name="email"
                        placeholder="ürün adı giriniz.."
                        type="email"
                        className='w-100 m-1'
                        style={{ height: '35px' }}
                    />
                    <Button className='btn btn-dark border-0 border' style={{ padding: '5px', marginLeft: '5px', marginTop: '2px', width: '40px', height: '35px' }}><i className="fa fa-search"></i></Button>
                </Col>
                <Col className={'col-md-5'}>
                    <Link to="/basket" className={'btn btn-dark'} style={{fontSize: 15}} >
                        <i className='faindex fa fa-shopping-cart'></i> &nbsp; {t('Basket.Title')}</Link>
                </Col>
            </Row>
            <br /><br /><br />
        </React.Fragment >
    )
};

export default NavBar;