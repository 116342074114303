import React from 'react';
import StandartPage from '../Layouts/Shared/_StandartPage';
import { useTranslation } from "react-i18next";
import { Card, CardImg, CardBody, CardTitle, CardText } from 'reactstrap';
import protectionOfPersonalDataImage from '../Assets/Images/page/protectionOfPersonalData/protectionOfPersonalData.jpg';

export default function ProtectionOfPersonalData() {

    const { t } = useTranslation();

    return (
        <StandartPage title={'Protection Of Personal Data Page'} topCategoryIsEnabled={true} topSliderIsEnabled={false} >
            <React.Fragment>
                <Card className="my-2">
                    <CardImg
                        alt="Card image cap"
                        src={protectionOfPersonalDataImage}
                        style={{
                            height: 435
                        }}
                        top
                        width="100%"
                    />
                    <CardBody>
                        <CardTitle tag="h5">
                            {t("Kişisel Verilerin Korunması")}
                        </CardTitle>
                        <CardText>
                            {t("Kişisel Verilerin Korunması ve İşlenmesi Şartları Hakkında Aydınlatma Metni")}
                            <br /><br />
                            {t('Tatalli Teknoloji ve Ticaret Ltd. Şti. (Tatalli) olarak, işbu Aydınlatma Metni ile,'
                                + ' Kişisel Verilerin Korunması Kanunu (“Kanun”) ve Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında'
                                + ' Tebliğ kapsamında aydınlatma yükümlüğümüzün yerine getirilmesi amaçlanmaktadır.')}
                            <br /><br />
                            {t("Bu kapsamda bilgi vermekle yükümlü olduğumuz konular aşağıdaki gibidir:")}
                            <br /><br />
                            <ul style={{ listStyleType: "upper-roman" }}>
                                <li><strong>{t("Veri Sorumlusu ve Varsa Temsilcisinin Kimliği")}</strong><br />
                                    {t("Veri sorumlusu;  ÜNALAN MAH. LİBADİYE CAD. EMAR RESIDENCE BLOK NO: 82E İÇ KAPI NO: 291 ÜSKÜDAR / İSTANBUL"
                                        + " adresinde mukim, İstanbul Ticaret Sicili Müdürlüğü’ne 435859-5 sicil numarası ile kayıtlı" +
                                        "Tatalli Teknoloji ve Ticaret Ltd. Şti.’dir.")}
                                </li>
                            </ul>
                        </CardText>
                        <CardText>
                            <small className="text-muted">
                                Last updated 3 mins ago
                            </small>
                        </CardText>
                    </CardBody>
                </Card>
            </React.Fragment>
        </StandartPage>
    )

}