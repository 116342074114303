import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'reactstrap';
//import { useTranslation } from "react-i18next";
import { Card, CardBody } from 'reactstrap';
import CategoryList from '../../Components/Category/CategoryList';
import Footer from '../../Components/Footer';
import NavBar from '../../Components/NavBar';
import BreadCrumb from '../../Components/BreadCrumb';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';

export default function _StandartPage({ children, title, topCategoryIsEnabled = true, topSliderIsEnabled = false }) {

    document.title = title;
    
    const [dataCategoryList, setDataCategoryList] = useState(null);

    React.useMemo(() => {
        if ((sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') || true) {
            let currentSession = sessionStorage.getItem("currentSession");
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.get(ApiGateway + '/market-place/general/category/list')
                .then(response => {
                    setDataCategoryList(response.data);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, []);

    return (
        <React.Fragment>
            <ToastContainer />
            <div className="page-content">
                <hr style={{
                    color: '#2e4053',
                    backgroundColor: '#2e4053',
                    height: .5,
                    borderColor: '#2e4053',
                    paddingBottom: '20px',
                    marginTop: '0px',
                    display: 'none'
                }} />
                <Container>
                    <NavBar />
                    <BreadCrumb title={title} pageTitle="Voltran" />
                    {<>
                        {topCategoryIsEnabled && topCategoryIsEnabled === true ? <Card className="border-0">
                            <CardBody>
                                <CategoryList dataCategoryList={dataCategoryList} slider={topSliderIsEnabled && topSliderIsEnabled} />
                            </CardBody>
                        </Card> : null}
                    </>}

                    <Row>
                        <Col className="mt-4">
                        </Col>
                    </Row>

                    <Card className='border-0'>
                        <CardBody>
                            {children && children}
                        </CardBody>
                    </Card>
                    <Card className='border-0'>
                        <CardBody>
                            <Footer dataCategoryList={dataCategoryList} />
                        </CardBody>
                    </Card>
                    <Row>
                        <Col className="mt-2">
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )

}