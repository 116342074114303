import React from 'react';
import { Breadcrumb, BreadcrumbItem, Row, Col } from 'reactstrap';
import { useTranslation } from "react-i18next";

const BreadCrumb = () => {

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Row className='d-none'>
                <Col className="col-md-10">

                </Col>
                <Col className='col-md-2'>
                <Breadcrumb listTag="div">
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    <BreadcrumbItem href="#" tag="a">{t('ProductName')}</BreadcrumbItem>
                    <BreadcrumbItem href="#" tag="a">Pages</BreadcrumbItem>
                    <BreadcrumbItem active tag="span">Page</BreadcrumbItem>
                </Breadcrumb>
                </Col>
            </Row>
        </React.Fragment >
    )
};

export default BreadCrumb;