import React, { useState, useEffect, useMemo } from 'react';
import Select from "react-select";
import { useTranslation } from "react-i18next";
import axios from 'axios';

function CompCrmSegmentDropdown({ onChange, selectedOption, isDisabled }) {
    const { t } = useTranslation();
    const [componentId] = useState('segmentList');

    const [selectedItem, setSelectedItem] = useState({});
    const [optionsListData, setOptionsListData] = useState([]);

    React.useMemo(() => {
        let list = [];
        if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
            let currentSession = sessionStorage.getItem("currentSession");
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.get(ApiGateway + '/crm/general/segment/list')
                .then(response => {
                    if (response.data?.isSucceed === true) {
                        list.push({ label: t('Select.Default.Option.Choose'), value: '-1' });
                        response.data?.payload?.forEach((item) => {
                            list.push({ label: item.label, value: item.value });
                        });
                    }
                    setOptionsListData(list);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [t]);

    useEffect(() => {
        let selected = {};
        optionsListData?.forEach((item) => {
            if (selectedOption !== null && item.value === selectedOption) {
                selected = { label: item.label, value: item.value };
            }
        });
        setSelectedItem(selected);
    }, [t, selectedOption, optionsListData]);

    const handleChangeSelect = (e) => {
        setSelectedItem({ label: e?.label, value: e?.value });
        onChange(e.value);
    };

    return (
        <React.Fragment>
            <div>
                {(optionsListData !== null && selectedItem !== null) ? (
                    <Select aria-label=".form-select-sm" classNamePrefix="select2-selection form-select" id={componentId}
                        noOptionsMessage={() => t('Select.Default.Option.NoOptions')} isDisabled={isDisabled === true ? "disabled" : ""} isOptionDisabled={(option) => option.isdisabled}
                        options={optionsListData} onChange={handleChangeSelect} value={selectedItem} selected={selectedItem?.value} />
                ) : (
                    <p>Yükleniyor...</p>
                )}
            </div>
        </React.Fragment>
    );
}
export default CompCrmSegmentDropdown;