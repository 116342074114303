import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import FooterCategoryList from './FooterCategoryList';

import footerImageCreditCartBonusCard from '../Assets/Images/footer/credit-cart/bonus-card.svg';
import footerImageCreditCartMaximum from '../Assets/Images/footer/credit-cart/maximum.svg';
import footerImageCreditCartWorld from '../Assets/Images/footer/credit-cart/world.svg';
import footerImageCreditCartZiraat from '../Assets/Images/footer/credit-cart/ziraat.svg';
import footerImageCreditCartQnb from '../Assets/Images/footer/credit-cart/qnb.png';
import footerImageCreditCartAxess from '../Assets/Images/footer/credit-cart/axess.svg';
import footerImageCreditCartHsbc from '../Assets/Images/footer/credit-cart/hsbc.svg';
import footerImageCreditCartParaf from '../Assets/Images/footer/credit-cart/paraf.svg';
import footerImageCreditCartVisa from '../Assets/Images/footer/credit-cart/visa.svg';
import footerImageCreditCartMasterCard from '../Assets/Images/footer/credit-cart/master-card.svg';
import footerImageCreditCartAmericanExpress from '../Assets/Images/footer/credit-cart/american-express.svg';
import footerImageCreditCartTroy from '../Assets/Images/footer/credit-cart/troy.svg';
import footerImageSecureShopping from '../Assets/Images/footer/guvenli-alisveris-icon.webp';


const Footer = (props) => {

    const { t } = useTranslation();

    const [data, setData] = useState(null);
    useEffect(() => {
        setData(props?.dataCategoryList);
    }, [props]);

    let styleOfFooterImageCredit = { marginRight: '15px' };

    return (
        <React.Fragment>
            <hr style={{
                color: '#2e4053',
                backgroundColor: '#2e4053',
                height: .5,
                borderColor: '#2e4053',
                paddingBottom: '5px'
            }} />

            <Row>
                <Col className='col-md-3'>
                    <h5 style={{ textAlign: "left" }}>Yuhdeve.com</h5>
                    <div className="d-flex p-2" style={{ textAlign: "left" }}>
                        <ul style={{ listStyleType: 'none', padding: '0px' }}>
                            <li><Link style={{ textDecoration: 'none' }} to="/about-us">{t('Footer.Link.AboutUs')}</Link></li>
                            <li><Link style={{ textDecoration: 'none' }} to="/contact">{t('Footer.Link.Contact')}</Link></li>
                            <li><Link style={{ textDecoration: 'none' }} to="/protection-of-personal-data">{t('Footer.Link.ProtectionOfPersonalData')}</Link></li>
                            <li><Link style={{ textDecoration: 'none' }} to="/frequently-asked-questions">{t('Footer.Link.FrequentlyAskedQuestions')}</Link></li>
                            <li></li>
                        </ul>
                    </div>
                </Col>
                <Col className='col-md-2'>
                    <h5 style={{ textAlign: "left" }}>Kategoriler</h5>
                    <div className="d-flex p-2" style={{ textAlign: "left" }}>
                        <ul style={{ listStyleType: 'none', padding: '0px' }}>
                            <FooterCategoryList dataCategoryList={data} />
                        </ul>
                    </div>
                </Col>
                <Col className='col-md-2'>
                    <h5 style={{ textAlign: "left" }}>Trend Markalar</h5>
                    <div className="d-flex p-2" style={{ textAlign: "left" }}>
                        <ul style={{ listStyleType: 'none', padding: '0px' }}>
                            <li><Link style={{ textDecoration: 'none' }} to="/">{t('No Name')}</Link></li>
                            <li></li>
                        </ul>
                    </div>
                </Col>
                <Col className='col-md-3'>
                    <h5 style={{ textAlign: "left" }}>Satıcı Olmak İstiyorum</h5>
                    <div className="d-flex p-2" style={{ textAlign: "left" }}>
                        <ul style={{ listStyleType: 'none', padding: '0px' }}>
                            <li><Link style={{ textDecoration: 'none' }} to="/being-a-seller">{t('Footer.Link.BeingASeller')}</Link></li>
                        </ul>
                    </div>
                </Col>
                <Col className='col-md-2'>
                    <h5 style={{ textAlign: "left" }}>Bizi Takip Edin </h5>
                    <div className="d-flex p-2" style={{ textAlign: "left" }}>
                        <ul style={{ listStyleType: 'none', padding: '0px' }}>
                            <li><Link style={{ textDecoration: 'none' }} to="#">{t('Facebook')}</Link></li>
                            <li><Link style={{ textDecoration: 'none' }} to="#">{t('Instagram')}</Link></li>
                            <li><Link style={{ textDecoration: 'none' }} to="#">{t('LinkedIn')}</Link></li>
                            <li></li>
                        </ul>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='col-md-9 pull-left'>
                    <img src={footerImageCreditCartBonusCard} alt={''} width={76} height={12} style={styleOfFooterImageCredit} />
                    <img src={footerImageCreditCartMaximum} alt={''} width={62} height={16} style={styleOfFooterImageCredit} />
                    <img src={footerImageCreditCartWorld} alt={''} width={62} height={20} style={styleOfFooterImageCredit} />
                    <img src={footerImageCreditCartZiraat} alt={''} width={58} height={15} style={styleOfFooterImageCredit} />
                    <img src={footerImageCreditCartQnb} alt={''} width={50} height={25} style={styleOfFooterImageCredit} />
                    <img src={footerImageCreditCartAxess} alt={''} width={43} height={14} style={styleOfFooterImageCredit} />
                    <img src={footerImageCreditCartHsbc} alt={''} width={134} height={16} style={styleOfFooterImageCredit} />
                    <img src={footerImageCreditCartParaf} alt={''} width={32} height={20} style={styleOfFooterImageCredit} />
                    <img src={footerImageCreditCartVisa} alt={''} width={51} height={16} style={styleOfFooterImageCredit} />
                    <img src={footerImageCreditCartMasterCard} alt={''} width={40} height={19} style={styleOfFooterImageCredit} />
                    <img src={footerImageCreditCartAmericanExpress} alt={''} width={27} height={22} style={styleOfFooterImageCredit} />
                    <img src={footerImageCreditCartTroy} alt={''} width={42} height={20} style={styleOfFooterImageCredit} />
                </Col>
                <Col className='col-md-3 pull-right'>
                    <img src={footerImageSecureShopping} alt={''} width={113} height={20} />
                </Col>
            </Row>
            <Row className='mt-3'>
                <Col className='col-md-12'>
                    {t('ProductName')} © Copyright 2024 Tatalli Teknoloji ve Ticaret Ltd. Şti. Her hakkı saklıdır.
                </Col>
            </Row>
        </React.Fragment >
    )
};

export default Footer;