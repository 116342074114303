import React, { useState, useEffect, useMemo } from 'react';
import Select from "react-select";
import { useTranslation } from "react-i18next";
import DataTable from 'react-data-table-component';
import axios from 'axios';
import bag from "../../Assets/Images/finance/bag.jpg";
import svgTry from "../../Assets/Images/finance/currency/try.svg";
import svgUsd from "../../Assets/Images/finance/currency/usd.svg";
import svgEur from "../../Assets/Images/finance/currency/eur.svg";
import svgGbp from "../../Assets/Images/finance/currency/gbp.svg";
import svgDefault from "../../Assets/Images/finance/currency/try.svg";
import { ToastContainer, toast } from 'react-toastify';

function CompDtCashDesk() {
    const { t } = useTranslation();
    const [componentId] = useState('compDtCashDesk');
    const [data, setData] = useState([]);

    React.useMemo(() => {
        if (sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '') {
            let currentSession = sessionStorage.getItem("currentSession");
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.get(ApiGateway + '/market-place/trader/my-cash-desk/list')
                .then(response => {
                    if (response.data?.isSucceed === true) {
                        setData(response.data?.payload);
                    }

                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, []);

    const [totalRows, setTotalRows] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [sort, setSort] = useState('id,ASC');


    const [deleteModal, setDeleteModal] = useState(false);
    const [recordId, setRecordId] = useState(false);

    const onGoToItemDetails = props => {
        //history.push('/product/' + props);
        //dispatch(mpMyCashDeskListApi(props));
    };

    const onBtnDeleteItem = props => {
        let message = 'Prohibited action!';
        toast.error(message, { autoClose: 1000 });
        console.log(props);
        setRecordId(props);
        //setDeleteModal(true);
    };

    const handleDeleteTask = () => {
        if (recordId) {
            //dispatch(mpMyCashDeskDeleteByIdApi({ cashDeskId: recordId }));
            //setDeleteModal(false);
            //dispatch(mpMyCashDeskListApi(pageNumber, pageSize, sort));
            //dispatch(mpMyCashDeskListApi(pageNumber, pageSize, sort));
        }
    };


    useEffect(() => {
        //dispatch(mpMyCashDeskListApi(pageNumber, pageSize, sort));
    }, []);


    const handlePageChange = page => {
        //dispatch(mpMyCashDeskListApi(page - 1, pageSize, sort));
    }

    const handlePerRowsChange = async (newPerPage, page) => {
        setPageSize(newPerPage);
        //dispatch(mpMyCashDeskListApi(0, newPerPage, sort));
    }

    const columns = [
        {
            name: <span className='font-weight-bold fs-13'>{t('Mp.CompMpMyCashDeskDataTable.Datatable.Th.Title')}</span>,
            selector: row =>
                <div className="d-flex align-items-center mt-2">
                    <div className="flex-shrink-0 mb-2">
                        <img src={bag} width={28} height={28} alt="" className="avatar-xs rounded-circle" />
                    </div>
                    <div className="flex-grow-1 ms-2">
                        <h6 className="mb-1">{/*<Link to={'/cash-desk/' + row.id + '/details'} className="text-body">{row.name}</Link>*/}{row.name}</h6>
                        <p className="text-muted mb-2">{row.bankAccount.bankBranch.name}</p>
                    </div>
                    <div className="flex-shrink-0">
                    </div>
                </div>
            ,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>{t('Mp.CompMpMyCashDeskDataTable.Datatable.Th.Currency')}</span>,
            selector: row => {
                switch (row.account?.currency?.shortCode) {
                    case "TRY":
                        return <span><img src={svgTry} width={21} height={21} alt="" className="avatar-xxs me-2" /> {row.account?.currency?.name}</span>;
                    case "USD":
                        return <span><img src={svgUsd} width={21} height={21} alt="" className="avatar-xxs me-2" /> {row.account?.currency?.name}</span>;
                    case "EUR":
                        return <span><img src={svgEur} width={21} height={21} alt="" className="avatar-xxs me-2" /> {row.account?.currency?.name}</span>;
                    case "GBP":
                        return <span><img src={svgGbp} width={21} height={21} alt="" className="avatar-xxs me-2" /> {row.account?.currency?.name}</span>;
                    default:
                        return <span><img src={svgDefault} width={21} height={21} alt="" className="avatar-xxs me-2" /> {row.account?.currency?.name}</span>;
                }
            },
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>{t('Mp.CompMpMyCashDeskDataTable.Datatable.Th.AccountHolder')}</span>,
            selector: row => row.bankAccount.bankAccountName,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>{t('Mp.CompMpMyCashDeskDataTable.Datatable.Th.AccountNu')}</span>,
            selector: row => row.bankAccount.bankAccountNumber,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>{t('Mp.CompMpMyCashDeskDataTable.Datatable.Th.Iban')}</span>,
            selector: row => row.bankAccount.bankAccountiban,
            sortable: true
        },
        {
            name: <span className='font-weight-bold fs-13'>{t('Product.Datatable.Th.Actions')}</span>,
            selector: row =>
                <div className="hstack gap-3">
                    <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => { onBtnDeleteItem(row.id) }}>
                        <i className="ri-delete-bin-5-fill align-bottom" />
                    </button>
                    <button className="btn btn-sm btn-soft-info edit-list d-none" onClick={() => { onGoToItemDetails(row.id) }}>
                        <i className="ri-file-text-line align-bottom" />
                    </button>
                </div>,
            sortable: false
        },
    ];

    const [pending, setPending] = useState(true);
    const [rows, setRows] = useState([]);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setRows(data?.content);
            setPending(false);
        }, 0);
        return () => clearTimeout(timeout);
    }, [data?.content]);

    React.useEffect(() => {
        setPageSize(10);
    }, [pageSize]);

    React.useEffect(() => {

    }, [pageNumber]);

    return (
        <React.Fragment>
            <ToastContainer />
            <DataTable
                id={componentId}
                columns={columns}
                data={rows}
                pagination={false}
                paginationServer
                onPageChange={page => setPageNumber(page)}
                paginationPerPage={pageSize}
                paginationTotalRows={totalRows}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                progressPending={pending}
                noDataComponent={t('Datatable.Label.NoData')}
                paginationComponentOptions={{
                    rowsPerPageText: t('Datatable.Label.RowsPerPage'),
                    rangeSeparatorText: t('Datatable.Label.OutOf'),
                }}
            />
        </React.Fragment>
    );
}
export default CompDtCashDesk;