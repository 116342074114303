import React from 'react';
import { useTranslation } from "react-i18next";
import StandartPage from '../Layouts/Shared/_StandartPage';

export default function Other() {

    const { t } = useTranslation();

    return (
        <StandartPage title={t('Contact.Title')} topCategoryIsEnabled={true} topSliderIsEnabled={false} >
            <React.Fragment>
                <div><h1>{t('Contact.Title')}</h1></div>
            </React.Fragment>
        </StandartPage>
    )

}