import React from 'react'
import { Col } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
export default function CategoryItem(props) {

    const { t } = useTranslation();

    let category = props?.itemDetails;
    let categoryTitle = t('language') === 'tr' ? category?.labelTr : category?.labelEn;
    let categoryUrl = '/category/' + category?.id + '/product-list';
    let categoryIcon = '';
    categoryIcon = category?.icon;

    return (
        <Col md={3}>
            <Link to={categoryUrl} className="btn w-100 m-1" style={{ fontSize: 15, textAlign: 'left', backgroundColor: '#fff', color:'#000' }} >
                <i className={categoryIcon}></i> &nbsp; {categoryTitle}</Link>
        </Col>

    )
}