import React from 'react'
import { useTranslation } from "react-i18next";
import {  Link } from "react-router-dom";
export default function FooterCategoryItem(props) {

    const { t } = useTranslation();

    let category = props?.itemDetails;
    let categoryTitle = t('language') === 'tr' ? category?.labelTr : category?.labelEn;
    let categoryUrl = '/category/' + category?.id + '/product-list';

    return (
            <li><Link to={categoryUrl} style={{ textDecoration: 'none' }}>{categoryTitle}</Link></li>
    )
}