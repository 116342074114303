import React, { useState, useEffect } from 'react';
import StandartPage from '../Layouts/Shared/_StandartPage';
import { useTranslation } from "react-i18next";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, } from 'reactstrap';




export default function FrequentlyAskedQuestions() {

    const { t } = useTranslation();

    const [open, setOpen] = useState('');
    const toggle = (id) => {
        if (open === id) {
            setOpen();
        } else {
            setOpen(id);
        }
    }

    return (
        <StandartPage title={'Frequently Asked Questions Page'} topCategoryIsEnabled={true} topSliderIsEnabled={false} >
            <React.Fragment>
                <Accordion flush open={open} toggle={toggle}>
                    <AccordionItem>
                        <AccordionHeader targetId="1">{t("Yuhdeve'de hangi ödeme seçenekleri geçerlidir?")}</AccordionHeader>
                        <AccordionBody accordionId="1">
                            {t("Yuhdeve'de yapacağınız alışverişlerde, tüm bankalara ait banka ve kredi kartları ile ödeme yapabilirsiniz.")}
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="2">{t("İade/ İptal işlemlerimde işlem tutarı kartıma nasıl aktarılır?")}</AccordionHeader>
                        <AccordionBody accordionId="2">
                            {t("İade işlemi, satıcı firmanın onayı sonrası kartınıza iletilir. Ücret iadesi, bankaların iade süreçlerine bağlı olarak değişiklik gösterebilir.")}
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader targetId="3">{t("Gizlilik Politikası")}</AccordionHeader>
                        <AccordionBody accordionId="3">
                            <ul style={{ listStyleType: "upper-roman" }}>
                                <li>{t('İşbu Gizlilik Politikası, Tatalli Teknoloji ve Ticaret Ltd. Şti.’ye ("Şirket") '
                                    + 'ait aralarında www.yuhdeve.com’un da bulunduğu internet siteleri ve mobil uygulamaları/platformları (“Platform”)'
                                    + ' kullanan tüm kişiler (“Kullanıcı”) için geçerlidir.'
                                    + 'Şirket, elektronik ticaret işlemleri nedeniyle elde ettiği veya Kullanıcılar tarafından üyelik formları ile veya sair surette '
                                    + ' kendisine iletilen kişisel verileri ve bilgileri, işbu Gizlilik Politikası ve Platform’da yer alan, kişisel verilere ilişkin ilgili'
                                    + 'Aydınlatma Metni’nde belirtilen haller haricinde üçüncü şahıslarla paylaşmamakta ve belirtilen amaçlar dışındaki hiçbir'
                                    + ' ticari amaçla kullanmamakta ve üçüncü kişilere aktarmamaktadır.')}
                                </li>
                                <br />
                                <li>{t("Kullanıcılar’a ait kişisel veriler ve gizli bilgiler; ancak resmi makamlarca talep edilmesi halinde ve yürürlükteki emredici mevzuat hükümleri"
                                    + ' gereğince açıklama yapılmak zorunda olunduğu durumlarda resmi makamlara açıklanacaktır.')}</li>
                                <br />
                                <li>{t("Kullanıcılar tarafından Şirket’e sağlanan veya Şirket tarafından edinilen bilgilerin ve Platform üzerinden"
                                    + ' gerçekleşen tüm işlemlerin güvenliği için bilgi ve işlemin mahiyetine göre Şirketimiz veya ilgili kuruluşça sistemlerde ve internet'
                                    + ' altyapısında, teknolojik imkânlar ve maliyet unsurları dâhilinde, uygun teknik ve idari tedbirler alınmıştır.')}</li>
                                <br />
                                <li>{t("Kullanıcı’nın, Şirket ile paylaşmış olduğu kişisel verilerinin Kullanıcı’ya ait olduğu kabul edilmektedir.")}</li>
                                <br />
                                <li>{t("Şirket, işbu Gizlilik Politikası’nın hükümlerini dilediği zaman güncelleyebilir, değiştirebilir veya yürürlükten kaldırabilir."
                                    + " Güncellenen, değiştirilen ya da yürürlükten kaldırılan her hüküm, yayın tarihinde Kullanıcı için hüküm ifade edecektir. Yapılan değişikliğin"
                                    + " ardından Kullanıcı’nın Platform’u kullanmaya devam ediyor olması, değişikliklere muvafakati anlamına gelecektir.")}</li>
                            </ul>
                            <br />
                            {t("Bilgilerinize sunarız.")}
                            <br />
                            {t("Tatalli Teknoloji ve Ticaret Ltd. Şti.")}
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            </React.Fragment>
        </StandartPage >
    )

}