import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from "react-i18next";
import StandartPage from '../../Layouts/Shared/_StandartPage';
import ProductDetails from '../../Components/Product/ProductDetails';
import { useParams } from "react-router-dom";

export default function Details() {

    const { t } = useTranslation();
    const params = useParams();
    let id = params.id;

    return (
        <StandartPage title={t('Mp.Bazaar.Details.Title')} topCategoryIsEnabled={true} topSliderIsEnabled={false} >
            <React.Fragment>
                <Card>
                    <CardBody>
                        <ProductDetails itemId={id} />
                    </CardBody>
                </Card>
            </React.Fragment>
        </StandartPage>
    )

}