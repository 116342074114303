import { Card, CardBody, Col, Row, Button } from 'reactstrap';
import { Rating } from 'primereact/rating';
import CurrencyFormat from 'react-currency-format';
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const wordsTruncate = (words, length) => {
    words = words?.trim(); //you need to decied wheather you do this or not
    length -= 3; // because '..'.length === 3
    if (length >= words?.length) return words;

    let oldResult = /\s/.test(words?.charAt(length));
    for (let i = length - 1; i > -1; i--) {
        const currentRusult = /\s/.test(words?.charAt(i))
        if (oldResult && !currentRusult) {
            return `${words.substr(0, i + 1)}..`;
        }
        oldResult = currentRusult;
    }
    return '..';
}

export default function ProductListItem(props) {

    let bazaar = props.itemDetails;

    let productImage = bazaar?.productImageFile?.cdnStorage.url;
    let cdnStorageSnapshots = bazaar?.productImageFile?.cdnStorage?.cdnStorageSnapshots;
    if (cdnStorageSnapshots !== undefined && cdnStorageSnapshots?.length > 0) {
        for (let x = 0; x < cdnStorageSnapshots?.length; x++) {
            if (cdnStorageSnapshots !== undefined && cdnStorageSnapshots[x]?.fileSnapshotType?.value === 'IMAGE_200X200') {
                productImage = cdnStorageSnapshots[x]?.url;
            }
        }
    }

    const { t } = useTranslation();
    const navigate = useNavigate();

    const onGoToBazaarDetails = props => {
        navigate('/product/' + props.id + '/details');
    };

    return (
        <Col md={3} className="p-2">
            <Card className="ribbon-box right overflow-hidden border-0">
                <CardBody className="text-center p-4" style={{ cursor: 'pointer' }} onClick={(e) => onGoToBazaarDetails(bazaar)}>
                    {bazaar?.deliveryTimeType?.value === 'NOW' && (
                        <div className="ribbon ribbon-info ribbon-shape trending-ribbon">
                            <i className="ri-flashlight-fill text-white align-bottom"></i>{" "}
                        </div>
                    )}
                    <img
                        data-dz-thumbnail=""
                        height={200}
                        width={200}
                        className={'rounded bg-light mx-auto d-block'}
                        alt={bazaar?.product?.name}
                        title={bazaar?.product?.name}
                        src={productImage}
                    />
                    <h5 className="mb-1 mt-4">
                        <Link
                            to="apps-ecommerce-seller-details"
                            className="link-primary"
                        >
                            {bazaar?.label}
                        </Link>
                    </h5>
                    <p className="text-muted mb-4">{bazaar?.name}</p>
                    <Row className="justify-content-center">
                        <Col lg={8}>

                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col lg={6} className="border-end-dashed border-end d-none">
                            <h6>{wordsTruncate((t('language') === "en" ? bazaar?.category?.labelEn : bazaar?.category?.labelTr), 25)}</h6>
                            <span className="text-muted" style={{ whiteSpace: 'pre-wrap' }}>
                                {t('Mp.Bazaar.Datatable.Th.DeliveryTime') + '\n '}
                                {bazaar?.deliveryTime}
                                {' '}
                                {bazaar?.deliveryTimeType?.label}
                            </span>
                        </Col>
                        <Col lg={12}>
                            <Row className='d-flex'>
                                <Col className='col-6 mt-2'>
                                    <div className='d-flex'>
                                        <Rating className={"text-end"} value={4} readOnly cancel={false} />
                                        <span className='ms-1'>1253</span>
                                    </div>
                                </Col>
                                <Col className='col-6'>
                                    <span className="float-start text-muted fs-4">
                                        <CurrencyFormat value={bazaar?.productListPrice} displayType={'text'} thousandSeparator={true} prefix={bazaar?.cashDesk?.account?.currency?.symbol + ' '} />
                                    </span>
                                </Col>
                            </Row>
                            <Row>
                                <Col className='col-12 mt-2'>
                                    <p className="text-start lh-50 fs-5 word-wrap">{wordsTruncate(bazaar?.product?.name, 50)}</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div className="d-none">
                        <Button
                            onClick={(e) => onGoToBazaarDetails(bazaar)}
                            to="#"
                            className="btn btn-dark w-100 w-100 mt-4 "
                        >
                            {t('Mp.Bazaar.Button.Label.Details')}
                        </Button>
                    </div>
                </CardBody>
            </Card>
        </Col>
    )
}