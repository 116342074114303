import React, { useEffect, useState } from 'react';
import { CardHeader } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { Card, CardBody } from 'reactstrap';
import ProductList from '../../Components/Product/ProductList';
import { useParams } from "react-router-dom";
import axios from 'axios';
import StandartPage from '../../Layouts/Shared/_StandartPage';

export default function CategoryProductListPage() {

    const params = useParams();
    let categoryId = params?.id;

    const { t } = useTranslation();

    const [data, setData] = useState(null);
    const [categoryTitle, setCategoryTitle] = useState(null);

    useEffect(() => {

    }, [categoryId]);

    useEffect(() => {
        let ApiGateway = process.env.REACT_APP_API_URL;
        axios.get(ApiGateway + '/market-place/general/category/' + categoryId)
            .then(response => {
                setData(response.data);
            })
            .catch(error => {
                console.error(error);
            });
    }, [categoryId]);

    useEffect(() => {
        setCategoryTitle(t('language') === 'tr' ? data?.payload?.labelTr : data?.payload?.labelEn);
    }, [t, categoryId, data]);

    return (
        <StandartPage title={categoryTitle} topCategoryIsEnabled={true} >
            <React.Fragment>
                <Card className="border-0">
                    <CardHeader>
                        <div className="d-flex p-2">
                            <strong>{categoryTitle}</strong> &nbsp;
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ProductList categoryId={categoryId} />
                    </CardBody>
                </Card>
            </React.Fragment>
        </StandartPage>
    )

}