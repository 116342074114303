import './App.css';
import { Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import CategoryProductListPage from './Pages/Category/CategoryProductListPage';
import ProductDetails from './Pages/Product/Details';
import Login from './Pages/Member/Login';
import Register from './Pages/Member/Register';
import MyAccount from './Pages/Member/MyAccount';
import MyMpCbiProcessList from './Pages/Member/MyMpCbiProcessList';
import CreateCbiProcess from './Pages/Member/Process/CBI/CreateCbiProcess';
import Basket from './Pages/Basket';
import Other from './Pages/Other';
import AboutUs from './Pages/AboutUs';
import Contact from './Pages/Contact';
import ProtectionOfPersonalData from './Pages/ProtectionOfPersonalData';
import FrequentlyAskedQuestions from './Pages/FrequentlyAskedQuestions';
import BeingASeller from './Pages/BeingASeller';
import ChangedLanguage from './Pages/ChangedLanguage';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path="/category/:id/product-list" element={<CategoryProductListPage />} />
        <Route exact path='/product/:id/details' element={<ProductDetails />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/register" element={<Register />} />
        <Route exact path="/my-account" element={<MyAccount />} />
        <Route exact path="/my-cbi-process-list" element={<MyMpCbiProcessList />} />
        <Route exact path="/create-cbi-process/:taskId" element={<CreateCbiProcess />} />
        <Route exact path="/basket" element={<Basket />} />
        <Route exact path="/other" element={<Other />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/protection-of-personal-data" element={<ProtectionOfPersonalData />} />
        <Route exact path="/frequently-asked-questions" element={<FrequentlyAskedQuestions />} />
        <Route exact path="/being-a-seller" element={<BeingASeller />} />
        <Route exact path="/changed-language" element={<ChangedLanguage />} />
      </Routes>
    </div>
  );
}

export default App;
