import React from 'react';
import { Row, Col, CardHeader } from 'reactstrap';
import { useTranslation } from "react-i18next";
import { Card, CardBody } from 'reactstrap';
import ProductList from '../Components/Product/ProductList';
import StandartPage from '../Layouts/Shared/_StandartPage';

export default function HOme() {

    const { t } = useTranslation();

    return (
        <StandartPage title={t('Dashboard.Title')} topCategoryIsEnabled={true} topSliderIsEnabled={true} >
            <React.Fragment>
                <Row>
                    <Col className="mt-4">
                    </Col>
                </Row>
                <Card className="border-0">
                    <CardHeader>
                        <div className="d-flex p-2">
                            <strong>Genel Alışveriş</strong> &nbsp;
                            Genel Alışveriş kategorisinde bir çok ürün ve hizmeti, üyelik ve abonelik paketlerini ve yöresel ürünleri inceleyebilirsiniz.
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ProductList categoryId={'23a30f6e-8ab7-445e-854f-b659ca585b6c'} showcase={true} limit={4} />
                    </CardBody>
                </Card>
                <Row>
                    <Col className="mt-4">
                    </Col>
                </Row>
                <Card className="border-0">
                    <CardHeader>
                        <div className="d-flex p-2">
                            <strong>Yazılım & Teknoloji</strong> &nbsp;
                            Wordpress Tema, Eklenti ve kurulum hizmetleri, E-ticaret, Mobil Uygulamalar ve dahası.
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ProductList categoryId={'bf78451e-3088-4f6e-9762-0d489fd36d0c'} showcase={true} limit={4} />
                    </CardBody>
                </Card>
                <Row>
                    <Col className="mt-4">
                    </Col>
                </Row>
                <Card className="border-0">
                    <CardHeader>
                        <div className="d-flex p-2">
                            <strong>Internet Reklamcılığı</strong> &nbsp;
                            Web Site Reklamları, Seo - Backlink Hizmetleri ve dahası en uygun fiyat seçeneğiyle..
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ProductList categoryId={'8606b214-bb26-44ec-87fd-b108478ede54'} showcase={true} limit={4} />
                    </CardBody>
                </Card>
                <Row>
                    <Col className="mt-4">
                    </Col>
                </Row>
                <Card className="border-0">
                    <CardHeader>
                        <div className="d-flex p-2">
                            <strong>Sosyal Medya Hizmetleri</strong> &nbsp;
                            Sosyal Medya Hizmetleri için aklınıza gelebilcek tüm  sosyal medya hizmetleri burada. En uygun fiyatlar, kaliteli hizmet şimdi ziyaret edin.
                        </div>
                    </CardHeader>
                    <CardBody>
                        <ProductList categoryId={'2a2e9953-82a9-4cf6-baf7-dc091bf6877c'} showcase={true} limit={4} />
                    </CardBody>
                </Card>
                <Row>
                    <Col className="mt-0">
                    </Col>
                </Row>
            </React.Fragment>
        </StandartPage>
    )

}