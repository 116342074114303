import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Col, Card, CardHeader, CardBody, Input, Row } from "reactstrap";
import StandartPage from '../Layouts/Shared/_StandartPage';
import productNoImage from "../Assets/Images/products/productNoImage.png";
import axios from 'axios';
export default function Basket() {

    const { t } = useTranslation();

    const [applicationInfo, setApplicationInfo] = useState({});
    const [data, setData] = useState(null);
    const [productList, setProductList] = useState([]);
    const [logged, setLogged] = useState(false);

    const [discountCode, setDiscountCode] = useState('');
    const [labelDiscountCode, setLabelDiscountCode] = useState('');
    const [charge, setCharge] = useState(0);
    const [tax, setTax] = useState(0);
    const [dis, setDis] = useState(0);
    const [eUsdRate, setEUsdRate] = useState(0);

    const [listTotal, setListTotal] = useState(0);
    const [subTotal, setSubTotal] = useState(0);
    const [total, setTotal] = useState(0);

    const [goTopaymentPage] = useState('btn btn-dark border-0 border mt-3');
    const [buttonEnabledDiscountCode] = useState('btn btn-dark border-0 border');

    useEffect(() => {
        if ((sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '')) {
            setLogged(true);
            let currentSession = sessionStorage.getItem("currentSession");
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            axios.defaults.headers.common["Authorization"] = currentSession !== null ? JSON.parse(currentSession)?.bearerToken : null;
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.get(ApiGateway + '/basket/basket/current')
                .then(response => {
                    let basketItems = [];
                    response.data?.payload?.items?.forEach((item) => {
                        let productImage = item?.basketItemDetail?.productImageFile?.cdnStorage.url;
                        let cdnStorageSnapshots = item?.basketItemDetail?.productImageFile?.cdnStorage?.cdnStorageSnapshots;
                        if (cdnStorageSnapshots !== undefined && cdnStorageSnapshots?.length > 0) {
                            for (let x = 0; x < cdnStorageSnapshots?.length; x++) {
                                if (cdnStorageSnapshots !== undefined && cdnStorageSnapshots[x]?.fileSnapshotType?.value === 'IMAGE_200X200') {
                                    productImage = cdnStorageSnapshots[x]?.url;
                                }
                            }
                        }
                        
                        let basketItem = {
                            id: item?.basketItemDetail.productId,
                            img: productImage !== null ? productImage : productNoImage,
                            name: t('language') === 'tr' ? item.nameTr : item.nameEn,
                            color: '',
                            data_attr: item.amount,
                            price: item.listPrice,
                            unitPrice: item.unitPrice,
                            total: item.sub,
                            exchangeUsdRate: response.data?.payload?.exchangeUsdRate,
                        };
                        basketItems.push(basketItem);
                    }
                    );
                    setProductList(basketItems);
                    setData(response.data?.payload);
                    setEUsdRate(response.data?.payload?.exchangeUsdRate);
                    setListTotal(response.data?.payload?.listPrice);
                    setSubTotal(response.data?.payload?.subTotal);
                    setTotal(response.data?.payload?.total);
                    let dis = (response.data?.payload?.discountTotal);
                    let tax = (response.data?.payload?.taxTotal);
                    if (subTotal !== 0) {
                        setCharge(0);
                    } else {
                        setCharge(0);
                    }
                    setTax(tax);
                    setDis(dis);
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }, [t, setCharge, subTotal]);

    useEffect(() => {

    }, [productList]);

    useEffect(() => {
        if (logged) {
            if (data?.isSucceed === true) {
                let message = t('Api.Default.Service.Response.Succeded');
                toast.success(message, { autoClose: 1000 });
            } else {
                data?.messages?.forEach(
                    item => toast.error(item.message, { autoClose: 5000 })
                )
            }
        }
        setApplicationInfo('MP');
    }, [t, data, logged]);

    function removeBasketItem(itemId, data_attr) {
        if ((sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '')) {
            let message = t('Product.Common.Label.Basket.Notification.PleaseWait');
            const requestBody = {
                sourceType: 'MARKET_PLACE',
                id: itemId,
                amount: data_attr
            };
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.post(ApiGateway + '/basket/basket/item/remove', requestBody)
                .then(response => {
                    setData(response.data);
                    window.location.href = '/basket';
                })
                .catch(error => {
                    console.error(error);
                });
            toast.warning(message, { autoClose: 200 });
        }
    }

    function countUP(itemId, prev_data_attr, itemPrice) {
        if ((sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '')) {
            let message = t('Product.Common.Label.Basket.Notification.PleaseWait');
            const requestBody = {
                sourceType: 'MARKET_PLACE',
                id: itemId,
                amount: 1,
            };
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.post(ApiGateway + '/basket/basket/item/add', requestBody)
                .then(response => {
                    setData(response.data);
                    window.location.href = '/basket';
                })
                .catch(error => {
                    console.error(error);
                });
            toast.warning(message, { autoClose: 200 });
        }
    }

    function countDown(itemId, prev_data_attr, itemPrice) {
        if ((sessionStorage.getItem("currentSession") !== null && sessionStorage.getItem("currentSession")?.bearerToken !== '')) {
            let message = t('Product.Common.Label.Basket.Notification.PleaseWait');
            const requestBody = {
                sourceType: 'MARKET_PLACE',
                id: itemId,
                amount: 1,
            };
            axios.defaults.headers.common["X-User-Language"] = 'TR';
            let ApiGateway = process.env.REACT_APP_API_URL;
            axios.post(ApiGateway + '/basket/basket/item/remove', requestBody)
                .then(response => {
                    setData(response.data);
                    window.location.href = '/basket';
                })
                .catch(error => {
                    console.error(error);
                });
            toast.warning(message, { autoClose: 200 });
        }
    }

    const handleChange = (event) => {
        setDiscountCode(event.target.value);
    };

    const onClickApplyDiscountCode = btn => {
        setLabelDiscountCode(discountCode);
    }


    const onClickCheckout = btn => {
        let message = t('Product.Common.Label.Bpm.Button.PleaseWait');
        toast.warning(message, { autoClose: 5000 });
    }

    const something = (event) => {
        if (event.keyCode === 13) {
            //onClickApplyDiscountCode();
        }
    }

    return (
        <StandartPage title={t('Basket.Title')} topCategoryIsEnabled={true} topSliderIsEnabled={false} >
            <React.Fragment>
                <ToastContainer />
                <Row className="mb-3">
                    <Col xl={8}>
                        <Row className="align-items-center gy-3 mb-3">
                            <div className="col-sm">
                                <div>
                                    <h5 className="fs-14 mb-0">{t('Basket.Label.NumberOfItems.Left')} ({productList.length} {t('Basket.Label.NumberOfItems.Right')})</h5>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <Link
                                    to="/"
                                    className="link-primary text-decoration-underline"
                                >
                                    {t('Basket.Label.ContinueShopping')}
                                </Link>
                            </div>
                        </Row>
                        {productList.map((basketItem, key) => (
                            <React.Fragment key={key}>
                                <Card className="product mt-3">
                                    <CardBody>
                                        <Row className="gy-3">
                                            <div className="col-sm-auto">
                                                <div className="avatar-lg bg-light rounded p-1">
                                                    <img
                                                        src={basketItem?.img}
                                                        alt=""
                                                        className="img-fluid d-block"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm">
                                                <h5 className="fs-14 text-truncate">
                                                    <Link
                                                        to={'/product/' + basketItem?.id + '/details'}
                                                        className="text-dark"
                                                    >
                                                        {basketItem?.name}
                                                    </Link>
                                                </h5>
                                                <ul className="list-inline text-muted">

                                                </ul>

                                                {<div className="input-step d-flex m-0">
                                                    <button
                                                        type="button"
                                                        className="minus"
                                                        onClick={() => {
                                                            countDown(basketItem.id, basketItem.data_attr, basketItem.price);
                                                        }}
                                                    >
                                                        –
                                                    </button>
                                                    &nbsp;&nbsp;
                                                    <Input
                                                        type="text"
                                                        className="product-quantity"
                                                        style={{width: "50px"}}
                                                        value={basketItem.data_attr}
                                                        name="demo_vertical"
                                                        readOnly
                                                    />&nbsp;&nbsp;
                                                    <button
                                                        type="button"
                                                        className="plus"
                                                        onClick={() => {
                                                            countUP(basketItem.id, basketItem.data_attr, basketItem.price);
                                                        }}
                                                    >
                                                        +
                                                    </button>
                                                </div>}
                                            </div>
                                            <div className="col-sm-auto">
                                                <div className="text-lg-end">
                                                    <p className="text-muted mb-1 d-none">{t('Basket.Label.ListPrice')}:</p>
                                                    <h5 className="fs-14">
                                                        ₺
                                                        <span id="ticket_price" className="product-price">
                                                            {" "}
                                                            {basketItem?.price?.toFixed(2)}
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                        </Row>
                                    </CardBody>

                                    <div className="card-footer">
                                        <div className="row align-items-center gy-3">
                                            <div className="col-sm">
                                                <div className="d-flex flex-wrap my-n1">
                                                    <div>
                                                        {<Link
                                                            to="#"
                                                            className="d-block text-body p-1 px-2"
                                                            onClick={() => removeBasketItem(basketItem.id, basketItem.data_attr)}
                                                        >
                                                            <i className="ri-delete-bin-fill text-muted align-bottom me-1"></i>{" "}
                                                            {t('Basket.Label.RemoveItemOnBasket')}
                                                        </Link>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-sm-auto d-none">
                                                <div className="d-flex align-items-center gap-2 text-muted">
                                                    <div> {t('Basket.Label.Total')} :</div>
                                                    <h5 className="fs-14 mb-0">
                                                        {" "}
                                                        <span className="product-line-price">
                                                            {" "}
                                                            {(basketItem.total)?.toFixed(2)}
                                                        </span>
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            </React.Fragment>
                        ))}

                        <div className="text-end mb-4">
                            <Link onClick={() => { onClickCheckout() }}
                                to="#"
                                className={goTopaymentPage}
                            >
                                <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>{" "}
                                {t('Basket.Label.Checkout')}
                            </Link>
                        </div>
                    </Col>

                    <Col xl={4}>
                        <div className="sticky-side-div">
                            <Card>
                                <CardHeader className="border-bottom-dashed">
                                    <h5 className="card-title mb-0">{t('Basket.OrderSummary.Label.OrderSummary')}</h5>
                                </CardHeader>
                                <CardHeader className="bg-soft-light border-bottom-dashed d-none">
                                    <div className="text-center">
                                        <h6 className="mb-2">
                                            {t('Basket.OrderSummary.Label.HaveAPromoCode')}
                                        </h6>
                                    </div>
                                    <div className="hstack gap-3 px-3 mx-n3">
                                        <Input onKeyDown={(e) => something(e)} onBlur={handleChange} onChange={handleChange}
                                            value={discountCode == null ? '' : discountCode}
                                            className="form-control me-auto"
                                            type="text"
                                            placeholder={t('Basket.OrderSummary.Placeholder.Label.EnteCouponCode')}
                                            aria-label="Add Promo Code here..."
                                        />
                                        <button type="button" onClick={() => { onClickApplyDiscountCode() }} className={buttonEnabledDiscountCode}>
                                            {t('Basket.OrderSummary.Button.Apply')}
                                        </button>
                                    </div>
                                </CardHeader>
                                <CardBody className="pt-2">
                                    <div className="table-responsive">
                                        <table className="table table-borderless mb-0">
                                            <tbody>
                                                <tr className='d-none'>
                                                    <td>{t('Basket.OrderSummary.Label.Charge')} : </td>
                                                    <td className="text-end" id="cart-charge">
                                                        ₺ {charge}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{t('Basket.OrderSummary.Label.ExchangeUsdRate')} (USD/TRY) : </td>
                                                    <td className="text-end" id="cart-tax">
                                                        ₺ {eUsdRate}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>{t('Basket.OrderSummary.Label.ListTotal')} :</td>
                                                    <td className="text-end" id="cart-listtotal">
                                                        ₺ {listTotal}
                                                    </td>
                                                </tr>
                                                {
                                                    dis !== 0 ?
                                                        <tr>
                                                            <td>
                                                                {t('Basket.OrderSummary.Label.Discount')}{" "}
                                                                <span className="text-muted">({labelDiscountCode})</span> :{" "}
                                                            </td>
                                                            <td className="text-end" id="cart-discount">
                                                                - ₺ {dis}
                                                            </td>
                                                        </tr>
                                                        : null
                                                }
                                                <tr>
                                                    <td>{t('Basket.OrderSummary.Label.SubTotal')} :</td>
                                                    <td className="text-end" id="cart-subtotal">
                                                        ₺ {subTotal}
                                                    </td>
                                                </tr>
                                                {
                                                    applicationInfo?.id === 'MP' ? null :
                                                        <tr>
                                                            <td>{t('Basket.OrderSummary.Label.Tax')} (20%) : </td>
                                                            <td className="text-end" id="cart-tax">
                                                                ₺ {tax}
                                                            </td>
                                                        </tr>
                                                }

                                                <tr className="table-active">
                                                    <th>{t('Basket.OrderSummary.Label.Total')} (TRY) :</th>
                                                    <td className="text-end">
                                                        <span className="fw-semibold" id="cart-total">
                                                            ₺ {total}
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>

                        </div>
                    </Col>
                </Row>
            </React.Fragment>
        </StandartPage>
    )

}